import React from 'react';
import { Button } from './index';
import { ButtonType } from './Button';

const DefaultCtaButton = ({ children, className, ...rest }: ButtonType) => {
  return (
    <Button className={`joinready-cta${className ? ` ${className}` : ''}`} {...rest}>
      {children ? children : 'Join Ready'}
    </Button>
  );
};

export default DefaultCtaButton;
