import React, { useCallback, useState, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { Button as BaseButton, DefaultCtaButton, BarTag } from '../../../Gui';
import { absolute, bodyBold, buttonDefault, Color, flex, hover, hoverChild, Transition } from '../../../../styles';
import { Link } from 'gatsby';
import { owlSelector } from '../../../../utils';
import { usePageState } from '../../SiteContainer';
import { LIGHT_DARK } from '../../../../constants';
import { NAV_DATA } from '../../../../data/nav';
import { FacebookIcon, InstagramIcon, TwitterIcon, LinkedInIcon } from '../../../Svg';

const Wrapper = styled.nav`
  ${flex('center', 'center', 'row')};
  ${owlSelector('left', 40)};
`;

const NavButtonWrapper = styled.div<{ $atTop?: boolean; $menuShowing; $theme: string }>`
  --primary: ${({ $theme, $menuShowing }) => ($theme === 'DARK' ? Color.WHITE : $menuShowing ? Color.WHITE : Color.BLACK)};
  --secondary: ${({ $theme }) => ($theme === 'DARK' ? Color.BLACK : Color.WHITE)};

  position: relative;

  > button {
    position: relative;
    transition: color ${Transition.DEFAULT};
    color: ${({ $atTop, $menuShowing }) => ($atTop || $menuShowing ? `var(--primary)` : Color.BLACK)};
    ${buttonDefault};

    &::after {
      content: '';
      ${absolute('-12px', '-20px', '-12px', '-20px')};
      background-color: ${Color.BLACK};
      z-index: -1;
      border-radius: 30px;
      opacity: ${({ $menuShowing }) => ($menuShowing ? 1 : 0)};
      transition: opacity ${Transition.DEFAULT};
    }
  }

  .point {
    width: 17px;
    height: 13px;
    position: absolute;
    left: calc(50% - 8px);
    top: 14px;
    overflow: hidden;

    &::before {
      content: '';
      width: 17px;
      height: 13px;
      position: absolute;
      background: black;
      top: 9px;
      left: 2px;
      background: rgba(255, 255, 255, 0.94);
      transform: skew(10deg) rotate(50deg);
    }
  }

  ul {
    position: absolute;
    left: calc(-273px / 2 + 50%);
    top: 27px;
    list-style: none;
    padding: 28px 28px 32px;
    width: 27.3rem;
    background: rgba(255, 255, 255, 0.94);
    ${owlSelector('top', 24)};
    border-radius: 20px;

    li a {
      ${bodyBold};
      color: ${Color.BLACK};
      display: inline-block;
    }
  }
`;

const NavCTAButton = styled(DefaultCtaButton)`
  padding: 20px 32px;

  ${({ $pageTheme, $atTop }) =>
    $pageTheme === LIGHT_DARK.DARK &&
    css`
      ${$atTop
        ? css`
            background-color: transparent;
            border: 1px solid ${Color.WHITE};
            color: ${Color.WHITE};

            &:hover {
              background-color: ${Color.WHITE};
              border: 1px solid ${Color.WHITE};
              color: ${Color.BLACK};
            }
          `
        : css`
            background-color: transparent;
            border: 1px solid ${Color.BLACK};
            color: ${Color.BLACK};

            &:hover {
              background-color: ${Color.BLACK};
              border: 1px solid ${Color.BLACK};
              color: ${Color.WHITE};
            }
          `}
    `};
`;

const Button = styled.button``;

const NavLink = styled(Link)<{ $pageTheme: string; $atTop?: boolean }>`
  ${({ $pageTheme, $atTop }) =>
    $pageTheme === LIGHT_DARK.DARK
      ? css`
          ${$atTop
            ? css`
                color: ${Color.WHITE};
              `
            : css`
                ${hover('color', Color.BLACK, Color.WHITE)};
              `}
        `
      : css`
          ${hover('color', Color.BLACK, Color.WHITE)};
        `};
  ${buttonDefault};

  &::after {
    content: '';
    ${absolute('-12px', '-20px', '-12px', '-20px')};
    background-color: ${Color.BLACK};
    z-index: -1;
    border-radius: 30px;
    opacity: 0;
    transition: opacity ${Transition.DEFAULT};
  }

  &:hover {
    &::after {
      opacity: 1;
    }
  }
`;

const Menu = styled.div`
  position: relative;
  transition: opacity ${Transition.DEFAULT};
  opacity: ${({ $menuShowing }) => ($menuShowing ? 1 : 0)};
  visibility: ${({ $menuShowing }) => ($menuShowing ? 'visible' : 'hidden')};
`;

const Anchor = styled.a<{ $pageTheme: string; $atTop?: boolean }>`
  ${({ $pageTheme, $atTop }) =>
    $pageTheme === LIGHT_DARK.DARK
      ? css`
          ${$atTop
            ? css`
                svg path {
                  fill: ${Color.WHITE};
                }
              `
            : css`
                ${hoverChild('svg path', 'fill', Color.BLACK, Color.WHITE)};
              `}
        `
      : css`
          ${hoverChild('svg path', 'fill', Color.BLACK, Color.WHITE)};
        `};
  padding: 2rem 1.2rem 1rem;
  border-radius: 30px;
  ${hover('background-color', 'transparent', Color.BLACK)};
`;

const SocialLinks = styled.ul`
  list-style: none;
  display: flex;
`;

const NavButton = ({
  data: { title, url, menuItems },
  index,
  activeIndex,
  setActiveIndex,
  atTop,
  pageTheme,
}: {
  data: { title: string; url?: string; menuItems?: any };
  index?: number;
  activeIndex?: number;
  setActiveIndex: Function;
  atTop: boolean;
  pageTheme: string;
}) => {
  const menuShowing = activeIndex === index;
  const openMenu = useCallback(() => setActiveIndex(index), []);

  const closeMenu = useCallback(() => setActiveIndex(-1), []);

  return useMemo(
    () => (
      <NavButtonWrapper $menuShowing={menuShowing} onMouseEnter={openMenu} $atTop={atTop} $theme={pageTheme}>
        {!menuItems && url ? (
          <NavLink to={url} $pageTheme={pageTheme} $atTop={atTop}>
            {title}
          </NavLink>
        ) : (
          <Button aria-haspopup={true} aria-expanded={menuShowing} onClick={openMenu} url={url}>
            {title}
          </Button>
        )}
        {menuItems ? (
          <Menu
            className={'list-container'}
            $menuShowing={menuShowing}
            onMouseLeave={closeMenu}
            onBlur={e => {
              if (!e.currentTarget.contains(e.relatedTarget)) {
                closeMenu();
              }
            }}>
            <div className={'point'} />
            {menuItems ? (
              <ul>
                {menuItems.map(({ title, tag, url }, index) => (
                  <li key={index}>{tag ? <BarTag title={title} tag={tag} /> : <Link to={url}>{title}</Link>}</li>
                ))}
              </ul>
            ) : null}
          </Menu>
        ) : null}
      </NavButtonWrapper>
    ),
    [menuShowing, pageTheme, atTop, openMenu, closeMenu]
  );
};

const NavButtons = ({ atTop, visible }: { atTop: boolean, visible: boolean }) => {
  const [activeItemIndex, setActiveItemIndex] = useState();
  const pageTheme = usePageState(state => state.pageTheme);

  if (visible) {    
    return useMemo(
      () => (
        <Wrapper>
          {NAV_DATA.map((item, index) => {
            return (
              <NavButton
                key={index}
                data={item}
                activeIndex={activeItemIndex}
                setActiveIndex={setActiveItemIndex}
                index={index}
                atTop={atTop}
                pageTheme={pageTheme}
              />
            );
          })}
          <SocialLinks>
            <li>
              <Anchor
                href="https://www.facebook.com/myreadylife"
                aria-label="Facebook. Opens in a new tab"
                target="_blank"
                $atTop={atTop}
                $pageTheme={pageTheme}>
                <FacebookIcon aria-hidden="true" />
              </Anchor>
            </li>
            <li>
              <Anchor
                href="https://www.linkedin.com/company/myreadylife"
                aria-label="LinkedIn. Opens in a new tab"
                target="_blank"
                $atTop={atTop}
                $pageTheme={pageTheme}>
                <LinkedInIcon aria-hidden="true" />
              </Anchor>
            </li>
            <li>
              <Anchor
                href="https://twitter.com/readylifetweets"
                aria-label="Twitter. Opens in a new tab"
                target="_blank"
                $atTop={atTop}
                $pageTheme={pageTheme}>
                <TwitterIcon aria-hidden="true" />
              </Anchor>
            </li>
            <li>
              <Anchor
                href="https://www.instagram.com/readylifeus/"
                aria-label="Instagram. Opens in a new tab"
                target="_blank"
                $atTop={atTop}
                $pageTheme={pageTheme}>
                <InstagramIcon aria-hidden="true" />
              </Anchor>
            </li>
          </SocialLinks>
          <NavCTAButton url={'/joinready'} className={'nav-cta'}  $atTop={atTop} theme={'SOLID_GREEN'}>
            Join Ready
          </NavCTAButton>
        </Wrapper>
      ),
      [pageTheme, atTop, activeItemIndex, setActiveItemIndex]
    );
  }
  else {
    return null;
  }
};

export default NavButtons;
